var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(this.siteName))]),
      _c(
        "Grid",
        {
          ref: "grid",
          attrs: {
            "data-items": _vm.result,
            filterable: true,
            filter: _vm.filter,
            "edit-field": "inEdit",
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            columns: _vm.columns,
            "selected-field": _vm.selectedField,
          },
          on: {
            filterchange: _vm.filterChange,
            sortchange: _vm.sortChangeHandler,
            itemchange: _vm.itemChange,
            dataStateChange: _vm.dataStateChange,
            pagechange: _vm.pageChange,
            rowclick: _vm.onRowClick,
          },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", {
                    attrs: { "data-item": props.dataItem },
                    on: {
                      edit: _vm.edit,
                      save: _vm.save,
                      remove: _vm.remove,
                      cancel: _vm.cancel,
                    },
                  }),
                ]
              },
            },
            {
              key: "isPaused",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("v-checkbox", {
                    staticClass: "ml-9",
                    on: {
                      change: function (e) {
                        return _vm.pauseComponentPopup(e, props.dataItem.id)
                      },
                    },
                    model: {
                      value: props.dataItem.is_paused,
                      callback: function ($$v) {
                        _vm.$set(props.dataItem, "is_paused", $$v)
                      },
                      expression: "props.dataItem.is_paused",
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "\n        font-weight-bold\n        text-xs\n        btn-default\n        bg-gradient-default",
                  attrs: {
                    disabled: _vm.user.claims.user_role == "customer_view",
                    title: "Add new",
                    ripple: false,
                    elevation: 0,
                  },
                  on: { click: _vm.insert },
                },
                [_vm._v(" Add new ")]
              ),
              _vm.hasItemsInEdit
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n        font-weight-bold\n        text-xs\n        btn-default\n        bg-gradient-danger",
                      attrs: {
                        title: "Cancel current changes",
                        ripple: false,
                        elevation: 0,
                      },
                      on: { click: _vm.cancelChanges },
                    },
                    [_vm._v(" Cancel current changes ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass:
                    "\n        font-weight-bold\n        text-xs\n        btn-default\n        bg-gradient-default",
                  attrs: {
                    disabled: _vm.user.claims.user_role == "customer_view",
                    title: "Pause/Unpause all",
                    ripple: false,
                    elevation: 0,
                  },
                  on: { click: _vm.pauseUnpauseAllPopup },
                },
                [_vm._v(" Pause/Unpause all ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: {
                    disabled: _vm.user.claims.user_role == "customer_view",
                    title: "Inverter Factors",
                    ripple: false,
                    elevation: 0,
                  },
                  on: { click: _vm.showInverterFactorDialog },
                },
                [_vm._v(" Show Monthly Inverter Adjustment Factors ")]
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "500" },
                      model: {
                        value: _vm.pause_unpause_dialog,
                        callback: function ($$v) {
                          _vm.pause_unpause_dialog = $$v
                        },
                        expression: "pause_unpause_dialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-h5 grey lighten-2" },
                            [_vm._v(" Pausing/Unpausing All Inverters ")]
                          ),
                          _c("v-card-text", { staticClass: "text-h8 mt-4" }, [
                            _vm._v(
                              " Are you sure? **Triple check** if there is a case open related to these before changing. These will be paused until manually undone. "
                            ),
                          ]),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-danger\n                  bg-gradient-danger\n                ",
                                  on: {
                                    click: function ($event) {
                                      _vm.pause_unpause_dialog = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-success\n                  bg-gradient-success\n                ",
                                  on: { click: _vm.pauseUnpauseAll },
                                },
                                [_vm._v(" Continue ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", width: "500" },
                      model: {
                        value: _vm.component_dialog,
                        callback: function ($$v) {
                          _vm.component_dialog = $$v
                        },
                        expression: "component_dialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-h5 grey lighten-2" },
                            [_vm._v(" Pausing/Unpausing Inverter ")]
                          ),
                          _c("v-card-text", { staticClass: "text-h8 mt-4" }, [
                            _vm._v(
                              " Are you sure? **Triple check** if there is a case open related to this before changing. This will be paused until it's manually undone. "
                            ),
                          ]),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-danger\n                  bg-gradient-danger\n                ",
                                  on: { click: _vm.cancelPauseInverter },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "\n                  font-weight-bold\n                  text-xs\n                  btn-success\n                  bg-gradient-success\n                ",
                                  on: { click: _vm.pauseInverter },
                                },
                                [_vm._v(" Continue ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.siteId
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("inverter-factor-dialog-k", {
                        attrs: {
                          "inverter-data": _vm.gridData,
                          "site-id": _vm.siteId,
                        },
                        on: { "reload-data": _vm.getData },
                        model: {
                          value: _vm.showInverterDialog,
                          callback: function ($$v) {
                            _vm.showInverterDialog = $$v
                          },
                          expression: "showInverterDialog",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("grid-norecords", [_vm._v(" There is no data available ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }