<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="1200"
    :style="{ height: '95%' }"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2 d-flex align-center">
        <span>Monthly Inverter Adjustment Factors</span>
        <v-divider vertical class="mx-3 my-1" style="height: 24px;"></v-divider>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="ml-2
              caption
              font-weight-medium
              primary--text
              text-decoration-underline"
              style="cursor: pointer"
              v-bind="attrs"
              v-on="on"
            >
              Case Info
            </span>
          </template>
          <div class="pa-2">
            <div><strong>Site:</strong> {{ siteId || "N/A" }}</div>
            <div v-if="caseInfo.openCases.length > 0 || caseInfo.closedCases.length > 0">
              <strong>Case Date Range:</strong> 
              {{ 
                getCaseDateRange(caseInfo.openCases, caseInfo.closedCases) 
              }}
            </div>
            <v-divider class="my-2"></v-divider>
            <div v-if="caseInfo.loading">
              <v-progress-circular
                indeterminate
                size="16"
                width="2"
                color="primary"
                class="mr-2"
              ></v-progress-circular>
              <span>Loading case data...</span>
            </div>
            <div v-else>
              <div class="d-flex justify-space-between">
                <span><strong>Open Cases:</strong></span>
                <span class="ml-2">{{ caseInfo.open }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <span><strong>Closed Cases:</strong></span>
                <span class="ml-2">{{ caseInfo.closed }}</span>
              </div>
              <div class="d-flex justify-space-between">
                <span><strong>Total Cases:</strong></span>
                <span class="ml-2">{{ caseInfo.open + caseInfo.closed }}</span>
              </div>
              
              <!-- Cases Table -->
              <div class="mt-2">
                <strong>Case Summary:</strong>
                <v-simple-table dense class="mt-1 case-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Status</th>
                        <th>Opened</th>
                        <th>Closed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Open Cases -->
                      <tr
                        v-for="(caseItem, index) in caseInfo.openCases.slice(
                          0,
                          3
                        )"
                        :key="'open-' + index"
                        class="open-case"
                      >
                        <td>{{ caseItem.subject || "No Subject" }}</td>
                        <td>
                          {{
                            (caseItem.case_status &&
                              caseItem.case_status.name) ||
                            "Unknown"
                          }}
                        </td>
                        <td>{{ formatDate(caseItem.date_added) }}</td>
                        <td>-</td>
                      </tr>
                      <!-- Closed Cases -->
                      <tr
                        v-for="(caseItem, index) in caseInfo.closedCases.slice(
                          0,
                          3
                        )"
                        :key="'closed-' + index"
                        class="closed-case"
                      >
                        <td>{{ caseItem.subject || "No Subject" }}</td>
                        <td>
                          {{
                            (caseItem.case_status &&
                              caseItem.case_status.name) ||
                            "Unknown"
                          }}
                        </td>
                        <td>{{ formatDate(caseItem.date_added) }}</td>
                        <td>
                          {{
                            caseItem.date_closed
                              ? formatDate(caseItem.date_closed)
                              : "-"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              
              <div
                v-if="caseInfo.openCases.length > 3"
                class="caption text-right mt-1"
              >
                + {{ caseInfo.openCases.length - 3 }} more open cases
              </div>
              
              <div
                v-if="caseInfo.closedCases.length > 3"
                class="caption text-right mt-1"
              >
                + {{ caseInfo.closedCases.length - 3 }} more closed cases
              </div>
            </div>
          </div>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
          size="24"
          width="3"
          class="ml-3"
        ></v-progress-circular>
      </v-card-title>
      <v-card-text v-if="!showConfirmation">
        <Grid
          ref="grid"
          :data-items="gridData"
          :edit-field="'inEdit'"
          @itemchange="itemChange"
          @rowclick="enableEditing"
          @blur="disableEditing"
          :pageable="pageable"
          :take="take"
          :skip="skip"
          :page-size="pageSize"
          :total="total"
          :columns="columns"
          @pagechange="pageChange"
        >
          <template v-slot:loadDataCell="{ props }">
            <v-tooltip 
              bottom 
              content-class="tooltip-wide" 
              :disabled="!hasAdjustmentData(props.dataItem.month)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :class="{
                    'font-weight-bold text-xs btn-success bg-gradient-success': hasAdjustmentData(props.dataItem.month),
                    'font-weight-bold text-xs btn-danger bg-gradient-danger': !hasAdjustmentData(props.dataItem.month)
                  }"
                  style="
                    min-width: 40px; 
                    height: 30px; 
                    font-size: 12px; 
                    text-align: center; 
                    line-height: 1.5;
                    margin-top: 10px;
                    margin-left: 10px;
                    pointer-events: auto;
                  "
                  @click="hasAdjustmentData(props.dataItem.month) ? loadAdjustmentFactorsForMonth(props.dataItem.month) : null"
                >
                  <template v-if="hasAdjustmentData(props.dataItem.month)">
                    ✔
                  </template>
                  <template v-else>
                    ✘
                  </template>
                </v-btn>
              </template>
              <div style="min-width: 300px; max-width: 400px;">
                <template v-if="hasAdjustmentData(props.dataItem.month)">
                  <div style="margin-bottom: 10px;">
                    <strong>Data Available</strong> - {{ props.dataItem.month }}<br>
                    {{ getDataSummaryForMonth(props.dataItem.month) }}
                  </div>
                  
                  <!-- Tooltip content -->
                  <div v-if="!dateRange || !dateRange.site">
                    Loading date range information...
                  </div>
                  <div v-else>
                    <div>
                      <strong>Site Date Range:</strong><br>
                      <div style="font-size: 12px;">
                        <span>Start: {{ formatDate(dateRange.site.start_date) }} &nbsp;</span>
                        <span>End: {{ formatDate(dateRange.site.end_date) }} &nbsp;</span>
                        <span>Total: {{ dateRange.site.total_days }} days</span>
                      </div>
                    </div>
                    
                    <div
                      v-if="
                        dateRange.inverters && dateRange.inverters.length > 0
                      "
                    >
                      <strong>Inverter Details:</strong>
                      <table style="width: 100%; border-collapse: collapse; font-size: 12px; margin-top: 5px;">
                        <thead>
                          <tr style="border-bottom: 1px solid #ddd;">
                            <th style="text-align: left; padding: 2px;">Name</th>
                            <th style="text-align: left; padding: 2px;">Start</th>
                            <th style="text-align: left; padding: 2px;">End</th>
                            <th style="text-align: right; padding: 2px;">Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="inv in dateRange.inverters" :key="inv.inverter_id" style="border-bottom: 1px solid #eee;">
                            <td style="padding: 2px;">{{ inv.name }}</td>
                            <td style="padding: 2px;">{{ formatDateShort(inv.start_date) }}</td>
                            <td style="padding: 2px;">{{ formatDateShort(inv.end_date) }}</td>
                            <td style="text-align: right; padding: 2px;">{{ inv.total_days }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <strong>No Data Available</strong><br>
                  Month: {{ props.dataItem.month }}<br>
                  <small>Click to load adjustment factors for this month</small>
                </template>
              </div>
            </v-tooltip>
          </template>
          <!-- <template v-slot:loadDataCell="{ props }">
            <v-btn>load</v-btn>
          </template>
          <template v-slot:saveButtonCell="{ props }">
            <v-btn>Save</v-btn>
          </template> -->
          <!-- <grid-norecords>No data available</grid-norecords> -->
        </Grid>
      </v-card-text>
      <v-card-text v-else>
        <p>
          Do you want to queue the inverter estimate recast for the past 12
          months before closing? This process may take up to 5 minutes to
          complete.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="showConfirmation">
          <v-btn color="red" text @click="confirmRecast">Yes, Recast</v-btn>
          <v-btn color="grey" text @click="cancelRecast">No, Just Close</v-btn>
        </template>
        <template v-else>
          <v-btn
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            :disabled="isLoading"
            @click="handleReload"
          >
            Reload
          </v-btn>
          <v-btn
            class="font-weight-bold text-xs btn-success bg-gradient-success"
            :disabled="isLoading"
            @click="disableEditing"
          >
            Save
          </v-btn>
          <v-btn
            class="font-weight-bold text-xs btn-danger bg-gradient-danger"
            :disabled="isLoading"
            @click="promptConfirmation"
          >
            Close
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";

Vue.use(VueToast);

export default {
  components: {
    Grid,
    "grid-norecords": GridNoRecords,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inverterData: {
      type: Array,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      caseInfo: {
        open: 0,
        closed: 0,
        loading: true,
        openCases: [],
        closedCases: []
      },
      dialog: this.value,
      showConfirmation: false,
      pageable: { pageSizes: [12] },
      skip: 0,
      take: 12,
      pageSize: 12,
      gridData: [],
      isLoading: false,
      adjustmentData: [],
      dateRangeData: null,
    };
  },
  computed: {
    total() {
      return this.gridData.length;
    },
    dateRange: {
      get() {
        return this.dateRangeData;
      },
      // set(value) {
      //   this.dateRangeData = value;
      // }
    },
    columns() {
      return [
        {
          field: "load",
          title: "Load Data",
          width: "110px",
          cell: "loadDataCell",
        },
        { field: "month", title: "Month", width: "120px", editable: false },
        ...this.inverterData.map((inverter) => ({
          field: `inv${inverter.id}`,
          title: inverter.name,
          editor: "numeric",
          editable: true,
          width: "150px",
          headerTemplate: `<span title="Inverter ID: ${inverter.id}\nSerial: ${inverter.serial || 'N/A'}\nModel: ${inverter.model || 'N/A'}">${inverter.name}</span>`,
        })),
        // { cell: "saveButtonCell", filterable: false, width: "100px" },
      ];
    },
  },
  created() {
    this.initializeGridData();
    // Load date range data for tooltip when component is created
    this.loadDateRangeData();
    // Load case information for tooltip
    this.loadCaseInfo();
  },
  
  mounted() {
    // Add tooltips after the grid is rendered
    this.$nextTick(() => {
      this.addTooltipsToGrid();
    });
  },
  
  updated() {
    // Re-add tooltips when the grid updates
    this.$nextTick(() => {
      this.addTooltipsToGrid();
    });
  },
  watch: {
    inverterData: {
      immediate: true, // React to initial value
      handler(newData) {
        console.log("Parent data changed, updating child...");
        this.gridData = [...newData]; // Update local data in the child
        this.initializeGridData();
      },
    },
    adjustmentData(newValue) {
      // console.log(`adjustment data changed ${newValue}`);
      // ✅ Force Vue to update UI when adjustmentData changes
      this.gridData = [...this.gridData];
    },
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      // When the popup opens, fetch adjustment factors
      if (newValue) {
        this.loadAdjustmentFactors();
        this.loadDateRangeData();
        this.loadCaseInfo();
      }
      this.$emit("input", newValue);
    },
  },
  methods: {
    getCaseDateRange(openCases, closedCases) {
      // Combine all cases
      const allCases = [...openCases, ...closedCases];
      
      if (allCases.length === 0) {
        return "No cases available";
      }
      
      // Find earliest and latest dates
      let earliestDate = null;
      let latestDate = null;
      
      allCases.forEach(caseItem => {
        // Check for earliest date (date_added)
        const dateAdded = new Date(caseItem.date_added);
        if (!earliestDate || dateAdded < earliestDate) {
          earliestDate = dateAdded;
        }
        
        // Check for latest date (either date_closed or date_added)
        if (caseItem.date_closed) {
          const dateClosed = new Date(caseItem.date_closed);
          if (!latestDate || dateClosed > latestDate) {
            latestDate = dateClosed;
          }
        } else if (!latestDate || dateAdded > latestDate) {
          latestDate = dateAdded;
        }
      });
      
      // Format the dates
      return `${this.formatDate(earliestDate)} - ${this.formatDate(latestDate)}`;
    },
    loadCaseInfo() {
      this.caseInfo.loading = true;
      
      // Check if we have a valid siteId
      if (!this.siteId) {
        console.warn("No siteId provided for loading case information");
        this.caseInfo.loading = false;
        return;
      }
      
      // Create promises for both API calls with site-specific endpoints
      const openCasesPromise = Service.getSiteCasesNotDone(this.siteId);
      const closedCasesPromise = Service.getSiteCasesByStatus(
        this.siteId,
        "Done"
      );
      
      // Execute both API calls in parallel
      Promise.all([openCasesPromise, closedCasesPromise])
        .then(([openResponse, closedResponse]) => {
          // Extract the case counts from the responses
          const openCases = openResponse.data || [];
          const closedCases = closedResponse.data || [];
          
          // Update the caseInfo object
          this.caseInfo = {
            open: openCases.length,
            closed: closedCases.length,
            openCases: openCases,
            closedCases: closedCases,
            loading: false
          };
          
          // Sort open cases by date_added (newest first)
          if (this.caseInfo.openCases.length > 0) {
            this.caseInfo.openCases.sort((a, b) => {
              return new Date(b.date_added) - new Date(a.date_added);
            });
          }
        })
        .catch((error) => {
          console.error(
            "Error loading case information for site ID " + this.siteId + ":",
            error
          );
          this.caseInfo.loading = false;
        });
    },
    loadDateRangeData() {
      // Load date range data when component is created
      if (!this.siteId) {
        console.warn("No siteId available, cannot load date range data");
        return;
      }
      
      Service.getFactorsDateRange(this.siteId)
        .then((response) => {
          // The API returns the data directly in response.data
          if (
            response.data &&
            (response.data.site || response.data.inverters)
          ) {
            this.dateRangeData = response.data;
          } else {
            console.warn(
              "Response data structure is not as expected:",
              response.data
            );
          }
        })
        .catch((error) => {
          console.error("Error loading date range:", error);
        });
    },
    hasAdjustmentData(month) {
      if (!this.adjustmentData || this.adjustmentData.length === 0) {
        // console.log(`[DEBUG] No adjustment data available.`);
        return false;
      }

      // ✅ Convert Month Name to Number If Needed
      const monthNumber = this.getMonthNumber(month);

      // console.log(`[DEBUG] Checking for month ${monthNumber} in adjustmentData...`);

      const found = this.adjustmentData.some((d) => {
        // console.log(`[DEBUG] Comparing: Data Month = ${Number(d.month)}, Lookup Month = ${monthNumber}`);
        return Number(d.month) === monthNumber;
      });

      // console.log(`[DEBUG] Result for month ${monthNumber}: ${found ? "✅ Found" : "❌ Not Found"}`);
      return found;
    },
    getDataSummaryForMonth(month) {
      if (!this.hasAdjustmentData(month)) {
        return "No adjustment data available";
      }
      
      const monthNumber = this.getMonthNumber(month);
      const adjustments = this.adjustmentData.filter(d => Number(d.month) === monthNumber);
      
      if (adjustments.length === 0) {
        return "No adjustment data available";
      }
      
      const inverterCount = new Set(adjustments.map(a => a.inverter_id)).size;
      const avgFactor = adjustments.reduce((sum, curr) => sum + Number(curr.adjustment_factor), 0) / adjustments.length;
      
      return `${inverterCount} inverters with adjustment data\nAverage factor: ${avgFactor.toFixed(3)}`;
    },

    
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", { 
        year: "numeric", 
        month: "short", 
        day: "numeric" 
      });
    },
    
    formatDateShort(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", { 
        year: "numeric", 
        month: "short"
      });
    },
    
    /**
     * Get confidence score for a specific month and inverter
     */
    getConfidenceScore(month, inverterId) {
      if (!this.adjustmentData) return null;
      const monthNumber = this.getMonthNumber(month);
      const adjustment = this.adjustmentData.find(
        (d) => Number(d.month) === monthNumber && Number(d.inverter_id) === Number(inverterId)
      );
      return adjustment?.confidence_score;
    },
    
    /**
     * Get predicted adjustment factor for a specific month and inverter
     */
    getPredictedAdjustmentFactor(month, inverterId) {
      if (!this.adjustmentData) return null;
      const monthNumber = this.getMonthNumber(month);
      const adjustment = this.adjustmentData.find(
        (d) => Number(d.month) === monthNumber && Number(d.inverter_id) === Number(inverterId)
      );
      return adjustment?.predicted_adjustment_factor;
    },
    
    /**
     * Get prediction error for a specific month and inverter
     */
    getPredictionError(month, inverterId) {
      if (!this.adjustmentData) return null;
      const monthNumber = this.getMonthNumber(month);
      const adjustment = this.adjustmentData.find(
        (d) => Number(d.month) === monthNumber && Number(d.inverter_id) === Number(inverterId)
      );
      return adjustment?.prediction_error;
    },
    
    /**
     * Add tooltips to grid cells using vanilla JavaScript
     */
    addTooltipsToGrid() {
      // Wait for the grid to be fully rendered
      setTimeout(() => {
        try {
          console.log("Adding tooltips to grid cells...");
          
          // Get all rows in the grid
          const rows = document.querySelectorAll('.k-grid-content tr.k-master-row');
          if (!rows.length) {
            console.warn("No rows found in the grid");
            return;
          }
          
          // Map column indices to inverter IDs
          // First, get the month column index (usually 1)
          let monthColIndex = 1; // Default assumption
          
          // Create a mapping of column indices to inverter IDs
          const colToInverterId = {};
          
          // Start from index 2 (after month column) and map each column to an inverter
          // We're assuming the columns are in the same order as inverterData
          for (let i = 0; i < this.inverterData.length; i++) {
            const colIndex = i + 2; // +2 because first column is checkbox/button, second is month
            colToInverterId[colIndex] = this.inverterData[i].id;
          }
          
          // Process each row
          rows.forEach(row => {
            // Get the month from the month cell
            const monthCell = row.querySelector(`td[data-grid-col-index="${monthColIndex}"]`);
            if (!monthCell) return;
            
            const month = monthCell.textContent.trim();
            if (!month || !this.hasAdjustmentData(month)) return;
            
            // Process each cell in the row that contains adjustment factors
            const cells = row.querySelectorAll('td[data-grid-col-index]');
            cells.forEach(cell => {
              const colIndex = parseInt(cell.getAttribute('data-grid-col-index'));
              
              // Skip the month cell
              if (colIndex === monthColIndex) return;
              
              // Skip if this column doesn't correspond to an inverter
              const inverterId = colToInverterId[colIndex];
              if (!inverterId) return;
              
              // Get the inverter object
              const inverter = this.inverterData.find(inv => inv.id === inverterId);
              if (!inverter) return;
              
              // Get the value from the input field (for numeric cells)
              let cellValue = '';
              const numericInput = cell.querySelector('.k-formatted-value');
              if (numericInput) {
                cellValue = numericInput.getAttribute('aria-valuenow');
              } else {
                cellValue = cell.textContent.trim();
              }
              
              // Skip empty cells
              if (!cellValue) return;
              
              // Get tooltip data
              const confidenceScore = this.getConfidenceScore(month, inverterId);
              const predictedFactor = this.getPredictedAdjustmentFactor(month, inverterId);
              const predictionError = this.getPredictionError(month, inverterId);
              
              // Skip if no data available
              if (!confidenceScore && !predictedFactor && !predictionError) return;
              
              // Create tooltip content
              let tooltipContent = `Confidence Score for ${inverter.name}: `;
              if (confidenceScore !== null && confidenceScore !== undefined) {
                // Convert to number if it's a string, then multiply by 100 and round to whole number
                const scoreValue = typeof confidenceScore === 'string' ? parseFloat(confidenceScore) : confidenceScore;
                if (!isNaN(scoreValue)) {
                  tooltipContent += `${Math.round(scoreValue * 100)}%`;
                } else {
                  tooltipContent += `${confidenceScore}`;
                }
              } else {
                tooltipContent += 'Not available';
              }
              
              // Add tooltip to the numeric input element
              if (numericInput) {
                numericInput.setAttribute("title", tooltipContent);
              } else {
                cell.setAttribute("title", tooltipContent);
              }
              
              // Add a visual indicator that this cell has a tooltip (if not already present)
              if (!cell.querySelector(".tooltip-indicator")) {
                const indicator = document.createElement("span");
                indicator.className = "tooltip-indicator";
                indicator.style.color = "#0077cc";
                indicator.style.cursor = "help";
                indicator.style.position = "absolute";
                indicator.style.right = "25px"; // Position to the left of the up/down arrows
                indicator.style.top = "5px";
                indicator.style.zIndex = "10";
                indicator.style.fontSize = "12px";
                indicator.textContent = "ℹ️";
                indicator.setAttribute("title", tooltipContent);
                
                // Find the appropriate container to add the indicator to
                const container = cell.querySelector('.k-numeric-wrap') || cell;
                
                // Make sure the container has position relative for absolute positioning of the indicator
                if (window.getComputedStyle(container).position === 'static') {
                  container.style.position = 'relative';
                }
                
                container.appendChild(indicator);
              }
            });
          });
          
          console.log("Tooltips added successfully");
        } catch (error) {
          console.error("Error adding tooltips:", error);
        }
      }, 500); // Give the grid time to render
    },
    loadAdjustmentFactorsForMonth(month) {
      const monthNumber = this.getMonthNumber(month);

      if (isNaN(monthNumber)) {
        console.error(`[ERROR] Invalid month value received: ${month}`);
        return;
      }

      console.log(`[DEBUG] Loading adjustment factors for month: ${monthNumber}`);

      const adjustments = this.adjustmentData?.filter((d) => Number(d.month) === monthNumber);

      if (adjustments.length > 0) {
        this.gridData = this.gridData.map((row) => {
          if (this.getMonthNumber(row.month) === monthNumber) {
            adjustments.forEach((adjustment) => {
              const field = `inv${adjustment.inverter_id}`;
              const newValue = Number(adjustment.adjustment_factor).toFixed(3); // ✅ Truncate to 4 decimals
              row[field] = Number(newValue);
              // row[`inv${adjustment.inverter_id}`] = Number(adjustment.adjustment_factor); // ✅ Ensure numeric value
            });
          }
          return row;
        });
        // Removed date range loading logic from here - moved to created hook

        Vue.$toast.success(`Loaded data for ${this.getMonthName(monthNumber)}`, { position: "top-right" });
      } else {
        Vue.$toast.error(`No data found for ${this.getMonthName(monthNumber)}`, { position: "top-right" });
      }
    },
    getMonthNumber(month) {
      // ✅ Convert Month Name to Number
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      if (typeof month === "string") {
        const index = monthNames.indexOf(month);
        return index !== -1 ? index + 1 : NaN; // Convert to number (Jan = 1, Dec = 12)
      }

      return Number(month); // Already a number, return as is
    },
    initializeGridData() {
      // Generate rows for each month (1–12) with associated factors
      this.gridData = Array.from({ length: 12 }, (_, i) => {
        const monthIndex = i + 1;
        const row = {
          month: this.getMonthName(monthIndex),
          inEdit: false, // Needed to allow inline editing
        };
        // const row = {
        //   load: true, // Needed for Load Data column
        //   save: true, // Needed for Save column
        //   month: this.getMonthName(monthIndex),
        //   inEdit: false, // Tracks edit state
        // };
        this.inverterData.forEach((inverter) => {
          const factor = inverter.factors.find((f) => f.month === monthIndex);
          row[`inv${inverter.id}`] = factor ? factor.factor : null;
        });
        return row;
      });
    },
    /**
     * ✅ Checks if a row is in edit mode
     */
    isRowInEditMode(row) {
      let flag = row.inEdit === true;
      console.log(`[DEBUG] ${flag} Checking edit mode for row:`, row);
      return flag;
    },
    handleReload() {
      // Emit an event to the parent
      this.$emit("reload-data");
      // Optionally, you can show a toast for user feedback
      Vue.$toast.success("Reloading data...", { position: "top-right" });
    },
    /**
     * ✅ Saves all inverter values for a given row
     */
    saveRow(row) {
      if (!this.isRowInEditMode(row)) {
        Vue.$toast.warning("Row is not in edit mode.", { position: "top-right" });
        return;
      }
      this.isLoading = true; // Start loading state

      try {
        const month = this.getMonthNumber(row.month);

        // 🚀 Fire all update requests in parallel (no need to wait)
        Object.keys(row).forEach((key) => {
          if (key.startsWith("inv")) {
            const inverterId = key.replace("inv", "");
            const value = row[key];

            if (value !== null && value !== undefined) {
              this.updateFactor(inverterId, month, value); // 🔥 Fires request but does NOT wait
            }
          }
        });

        // ✅ Immediately update UI without waiting for responses
        this.gridData = this.gridData.map((r) =>
          r === row ? { ...r, inEdit: false } : r
        );

        Vue.$toast.success(`Factors saved for ${row.month}!`, { position: "top-right" });

      } catch (error) {
        console.error("[ERROR] Failed to save inverter values:", error);
        Vue.$toast.error("Error saving inverter factors. Please try again.", { position: "top-right" });

      } finally {
        this.isLoading = false; // ✅ Stop loading even if requests are still pending
      }
    },
    loadAdjustmentFactors() {
      //debugger;
      console.log('loadAdjustmentFactors');
      this.isLoading = true;
      Service.generateFactors(this.siteId)
        .then((response) => {
          this.adjustmentData = response.data.data; // Assuming response has { data: [...] }
          // ✅ Force UI refresh to update buttons
          this.gridData = [...this.gridData];
          
          // Add tooltips after data is loaded
          this.$nextTick(() => {
            this.addTooltipsToGrid();
          });

          Vue.$toast.success("Adjustment factors loaded successfully!", {
            position: "top-right",
          });
          //return Service.getFactorsDateRange(this.siteId);
        })
        // .then((response) => {
        //   this.dateRange = response.data.data;
        //   this.isDateRangeLoaded = true;
        //   console.log(this.dateRange);
        //   // this.componentProps.dateRange = dateRange;
        // })
        .catch(() => {
          Vue.$toast.error("Error loading adjustment factors.", {
            position: "top-right",
          });
        })
        .finally(() => {
          this.isLoading = false; // ✅ Hide spinner after data loads
        });
    },
    disableEditing() {
      //debugger;
      const editedRow = this.gridData.find((row) => row.inEdit); // ✅ Find the row being edited

      if (!editedRow) {
        Vue.$toast.warning("No row is currently being edited.", { position: "top-right" });
        return;
      }

      const month = this.getMonthNumber(editedRow.month);

      Object.keys(editedRow).forEach((key) => {
        if (key.startsWith("inv")) {
          const inverterId = key.replace("inv", "");
          const value = editedRow[key];

          if (value !== null && value !== undefined) {
            // ✅ Call updateFactor() to save the value
            this.updateFactor(inverterId, month, value);
          }
        }
      });

      // ✅ Disable editing only for the saved row
      this.gridData = this.gridData.map((row) =>
        row === editedRow ? { ...row, inEdit: false } : row
      );

      Vue.$toast.success(`Factors saved for ${editedRow.month}!`, { position: "top-right" });
    },
    getMonthName(month) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[month - 1];
    },
    /**
     * Enables editing mode when clicking a cell.
     */
    enableEditing(event) {
      const { dataItem } = event;

      console.log(`[DEBUG] Enabling edit mode for:`, dataItem);
      // Set `inEdit` to true for the clicked row
      this.gridData = this.gridData.map((item) =>
        item.month === dataItem.month ? { ...item, inEdit: true } : { ...item, inEdit: false }
      );
    },
    itemChange(event) {
      const { field, value, dataItem } = event;

      // Validate numeric input
      if (isNaN(value) || value < 0) {
        Vue.$toast.error("Please enter a valid positive number.", { position: "top-right" });
        return;
      }

      // Find and update the grid data
      const updatedData = this.gridData.slice();
      const index = updatedData.findIndex((item) => item.month === dataItem.month);
      if (index >= 0) {
        updatedData[index][field] = value;
        this.gridData = updatedData;

        // Extract inverter ID and month
        const inverterId = field.replace("inv", "");
        const month = dataItem.month;

        // Update the backend
        this.updateFactor(inverterId, month, value);
      }
      // ✅ Automatically disable editing when done
      //this.disableEditing();
    },
    updateFactor(inverterId, month, value) {
      const params = { month, factor: value };

      Service.updateInverterFactor(inverterId, params)
        .then(() => {
          Vue.$toast.success(`Factor updated for ${month}`, { position: "top-right" });
        })
        .catch(() => {
          Vue.$toast.error("Error updating factor.", { position: "top-right" });
        });
    },
    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    promptConfirmation() {
      this.showConfirmation = true;
    },
    confirmRecast() {
      const siteId = this.siteId;

      Service.recastInverterEstimates(siteId)
        .then((response) => {
          Vue.$toast.success(
            "Recast process has been queued successfully. Please wait approximately 5 minutes for the updates to reflect.",
            {
              position: "top-right",
            }
          );
          console.log("Recast response:", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error recasting inverter estimates.", {
            position: "top-right",
          });
          console.error("Recast error:", error);
        })
        .finally(() => {
          this.showConfirmation = false; // Hide the confirmation prompt
          this.dialog = false; // Close the dialog
        });
    },
    cancelRecast() {
      this.showConfirmation = false;
      this.dialog = false;
    },
  },
};
</script>
