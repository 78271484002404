var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: { height: "95%" },
      attrs: { persistent: "", "max-width": 1200 },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "text-h5 grey lighten-2 d-flex align-center" },
            [
              _c("span", [_vm._v("Monthly Inverter Adjustment Factors")]),
              _c("v-divider", {
                staticClass: "mx-3 my-1",
                staticStyle: { height: "24px" },
                attrs: { vertical: "" },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "span",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass:
                                    "ml-2\n            caption\n            font-weight-medium\n            primary--text\n            text-decoration-underline",
                                  staticStyle: { cursor: "pointer" },
                                },
                                "span",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Case Info ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c("div", [
                        _c("strong", [_vm._v("Site:")]),
                        _vm._v(" " + _vm._s(_vm.siteId || "N/A")),
                      ]),
                      _vm.caseInfo.openCases.length > 0 ||
                      _vm.caseInfo.closedCases.length > 0
                        ? _c("div", [
                            _c("strong", [_vm._v("Case Date Range:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getCaseDateRange(
                                    _vm.caseInfo.openCases,
                                    _vm.caseInfo.closedCases
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("v-divider", { staticClass: "my-2" }),
                      _vm.caseInfo.loading
                        ? _c(
                            "div",
                            [
                              _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: {
                                  indeterminate: "",
                                  size: "16",
                                  width: "2",
                                  color: "primary",
                                },
                              }),
                              _c("span", [_vm._v("Loading case data...")]),
                            ],
                            1
                          )
                        : _c("div", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("span", [
                                  _c("strong", [_vm._v("Open Cases:")]),
                                ]),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(_vm.caseInfo.open)),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("span", [
                                  _c("strong", [_vm._v("Closed Cases:")]),
                                ]),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(_vm.caseInfo.closed)),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("span", [
                                  _c("strong", [_vm._v("Total Cases:")]),
                                ]),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.caseInfo.open + _vm.caseInfo.closed
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c("strong", [_vm._v("Case Summary:")]),
                                _c("v-simple-table", {
                                  staticClass: "mt-1 case-table",
                                  attrs: { dense: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("Subject")]),
                                              _c("th", [_vm._v("Status")]),
                                              _c("th", [_vm._v("Opened")]),
                                              _c("th", [_vm._v("Closed")]),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(
                                                _vm.caseInfo.openCases.slice(
                                                  0,
                                                  3
                                                ),
                                                function (caseItem, index) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: "open-" + index,
                                                      staticClass: "open-case",
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            caseItem.subject ||
                                                              "No Subject"
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (caseItem.case_status &&
                                                                caseItem
                                                                  .case_status
                                                                  .name) ||
                                                                "Unknown"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              caseItem.date_added
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [_vm._v("-")]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._l(
                                                _vm.caseInfo.closedCases.slice(
                                                  0,
                                                  3
                                                ),
                                                function (caseItem, index) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: "closed-" + index,
                                                      staticClass:
                                                        "closed-case",
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            caseItem.subject ||
                                                              "No Subject"
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (caseItem.case_status &&
                                                                caseItem
                                                                  .case_status
                                                                  .name) ||
                                                                "Unknown"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatDate(
                                                              caseItem.date_added
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              caseItem.date_closed
                                                                ? _vm.formatDate(
                                                                    caseItem.date_closed
                                                                  )
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _vm.caseInfo.openCases.length > 3
                              ? _c(
                                  "div",
                                  { staticClass: "caption text-right mt-1" },
                                  [
                                    _vm._v(
                                      " + " +
                                        _vm._s(
                                          _vm.caseInfo.openCases.length - 3
                                        ) +
                                        " more open cases "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.caseInfo.closedCases.length > 3
                              ? _c(
                                  "div",
                                  { staticClass: "caption text-right mt-1" },
                                  [
                                    _vm._v(
                                      " + " +
                                        _vm._s(
                                          _vm.caseInfo.closedCases.length - 3
                                        ) +
                                        " more closed cases "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
              _vm.isLoading
                ? _c("v-progress-circular", {
                    staticClass: "ml-3",
                    attrs: {
                      indeterminate: "",
                      color: "primary",
                      size: "24",
                      width: "3",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          !_vm.showConfirmation
            ? _c(
                "v-card-text",
                [
                  _c("Grid", {
                    ref: "grid",
                    attrs: {
                      "data-items": _vm.gridData,
                      "edit-field": "inEdit",
                      pageable: _vm.pageable,
                      take: _vm.take,
                      skip: _vm.skip,
                      "page-size": _vm.pageSize,
                      total: _vm.total,
                      columns: _vm.columns,
                    },
                    on: {
                      itemchange: _vm.itemChange,
                      rowclick: _vm.enableEditing,
                      blur: _vm.disableEditing,
                      pagechange: _vm.pageChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "loadDataCell",
                          fn: function (ref) {
                            var props = ref.props
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    "content-class": "tooltip-wide",
                                    disabled: !_vm.hasAdjustmentData(
                                      props.dataItem.month
                                    ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: {
                                                      "font-weight-bold text-xs btn-success bg-gradient-success":
                                                        _vm.hasAdjustmentData(
                                                          props.dataItem.month
                                                        ),
                                                      "font-weight-bold text-xs btn-danger bg-gradient-danger":
                                                        !_vm.hasAdjustmentData(
                                                          props.dataItem.month
                                                        ),
                                                    },
                                                    staticStyle: {
                                                      "min-width": "40px",
                                                      height: "30px",
                                                      "font-size": "12px",
                                                      "text-align": "center",
                                                      "line-height": "1.5",
                                                      "margin-top": "10px",
                                                      "margin-left": "10px",
                                                      "pointer-events": "auto",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.hasAdjustmentData(
                                                          props.dataItem.month
                                                        )
                                                          ? _vm.loadAdjustmentFactorsForMonth(
                                                              props.dataItem
                                                                .month
                                                            )
                                                          : null
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm.hasAdjustmentData(
                                                  props.dataItem.month
                                                )
                                                  ? [_vm._v(" ✔ ")]
                                                  : [_vm._v(" ✘ ")],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "min-width": "300px",
                                        "max-width": "400px",
                                      },
                                    },
                                    [
                                      _vm.hasAdjustmentData(
                                        props.dataItem.month
                                      )
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "10px",
                                                },
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v("Data Available"),
                                                ]),
                                                _vm._v(
                                                  " - " +
                                                    _vm._s(props.dataItem.month)
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getDataSummaryForMonth(
                                                        props.dataItem.month
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            !_vm.dateRange ||
                                            !_vm.dateRange.site
                                              ? _c("div", [
                                                  _vm._v(
                                                    " Loading date range information... "
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _c("div", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "Site Date Range:"
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Start: " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  _vm.dateRange
                                                                    .site
                                                                    .start_date
                                                                )
                                                              ) +
                                                              "  "
                                                          ),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            "End: " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  _vm.dateRange
                                                                    .site
                                                                    .end_date
                                                                )
                                                              ) +
                                                              "  "
                                                          ),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            "Total: " +
                                                              _vm._s(
                                                                _vm.dateRange
                                                                  .site
                                                                  .total_days
                                                              ) +
                                                              " days"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                  _vm.dateRange.inverters &&
                                                  _vm.dateRange.inverters
                                                    .length > 0
                                                    ? _c("div", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            "Inverter Details:"
                                                          ),
                                                        ]),
                                                        _c(
                                                          "table",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                              "border-collapse":
                                                                "collapse",
                                                              "font-size":
                                                                "12px",
                                                              "margin-top":
                                                                "5px",
                                                            },
                                                          },
                                                          [
                                                            _c("thead", [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "border-bottom":
                                                                      "1px solid #ddd",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "left",
                                                                          padding:
                                                                            "2px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Name"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "left",
                                                                          padding:
                                                                            "2px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Start"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "left",
                                                                          padding:
                                                                            "2px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "End"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "text-align":
                                                                            "right",
                                                                          padding:
                                                                            "2px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Days"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.dateRange
                                                                  .inverters,
                                                                function (inv) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: inv.inverter_id,
                                                                      staticStyle:
                                                                        {
                                                                          "border-bottom":
                                                                            "1px solid #eee",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "2px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              inv.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "2px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatDateShort(
                                                                                inv.start_date
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "2px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.formatDateShort(
                                                                                inv.end_date
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "text-align":
                                                                                "right",
                                                                              padding:
                                                                                "2px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              inv.total_days
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                          ]
                                        : [
                                            _c("strong", [
                                              _vm._v("No Data Available"),
                                            ]),
                                            _c("br"),
                                            _vm._v(
                                              " Month: " +
                                                _vm._s(props.dataItem.month)
                                            ),
                                            _c("br"),
                                            _c("small", [
                                              _vm._v(
                                                "Click to load adjustment factors for this month"
                                              ),
                                            ]),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3361807927
                    ),
                  }),
                ],
                1
              )
            : _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    " Do you want to queue the inverter estimate recast for the past 12 months before closing? This process may take up to 5 minutes to complete. "
                  ),
                ]),
              ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.showConfirmation
                ? [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "red", text: "" },
                        on: { click: _vm.confirmRecast },
                      },
                      [_vm._v("Yes, Recast")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "grey", text: "" },
                        on: { click: _vm.cancelRecast },
                      },
                      [_vm._v("No, Just Close")]
                    ),
                  ]
                : [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "font-weight-bold text-xs btn-success bg-gradient-success",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.handleReload },
                      },
                      [_vm._v(" Reload ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "font-weight-bold text-xs btn-success bg-gradient-success",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.disableEditing },
                      },
                      [_vm._v(" Save ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "font-weight-bold text-xs btn-danger bg-gradient-danger",
                        attrs: { disabled: _vm.isLoading },
                        on: { click: _vm.promptConfirmation },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }